@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/normalize.css/normalize.css";
//@import "~nouislider/distribute/nouislider.min.css";
@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "../node_modules/nouislider/dist/nouislider.css";


body {
  user-select: none;
}

.rtl {
  direction: rtl;
}


.text-right {
  text-align: right !important;
}

button.mat-mdc-menu-item {
  width: auto !important;
}

// *, *::before, *::after {
//   box-sizing: unset !important;
// }

img {
  vertical-align: initial !important;
}


.table th, .table td{
  border-top: none !important;
}

td.mat-mdc-cell, td.mat-mdc-footer-cell, th.mat-mdc-header-cell {
  padding: 0;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
}

.table thead th {
  vertical-align: middle !important;
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}

.table th, .table td {
  vertical-align: middle !important;
}

body {
  line-height: unset !important;
  text-align: unset !important;
}
.btn-link{
  color: unset !important;
}

.custom-select{
  background: unset;
  border: 1px solid #ced4da;
  border-radius: 0rem;
}

.accordion > .card {
  margin-top: 4px;
}

.cut-txt{
  display: block;
  max-width: 109px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 6px solid transparent;
  background: lightgray;
  opacity: 0.4;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label.mat-tab-label-active{
  opacity: 1;
  }

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout{
    margin-bottom: unset;
  }

.bulkBins-container .mat-mdc-dialog-container{
  border-radius: 30px !important;
  padding: unset;
  overflow: unset;
  min-width: 481px;
}

.mdc-dialog__surface{
  border-radius: 30px !important;
}
  
.bulkBins-container-mobile .mat-mdc-dialog-container{
  border-radius: 30px;
  padding: unset;
  overflow: unset;
}

.popup-container-mobile .mat-mdc-dialog-container{
  padding: unset;
}

.popup-mobile .mat-mdc-dialog-container{
  border-radius: 30px;
  box-shadow: 2px 5px 16px 0px rgba(93, 120, 112, 0.20);
  padding: unset;
}
.popup-container-mobile{
  max-width: unset !important;
  width: 100%;
  height: 100%;
}

.cdk-global-overlay-wrapper:has(.clusterInfo-container-ltr) {
  justify-content: right !important;
  align-items: unset !important;
}

.cdk-global-overlay-wrapper:has(.clusterInfo-container-rtl) {
  justify-content: left !important;
  align-items: unset !important;
}

.clusterInfo-container-rtl .mat-mdc-dialog-container{
  border-radius: 0px 24px 24px 0px;
  padding: 24px;
  background: white;
}

.clusterInfo-container-ltr .mat-mdc-dialog-container{
  border-radius: 24px 0px 0px 24px;
  padding: 24px;
  background: white;
}

.carousel-control{
  padding: 0;
  background: 0 0;
  border: 0;
}

.table > :not(caption) > * > *{
  padding: unset !important;
}

.mat-mdc-tab-body-content{
  overflow: hidden !important;
}

.mat-mdc-text-field-wrapper{
  padding: unset !important;
}

.mat-mdc-form-field-input-control{
  height: 100% !important;
}

.mdc-text-field--filled.mdc-text-field--disabled{
  background-color: transparent !important;
}

div.mat-mdc-autocomplete-panel{
  padding: unset !important;
}

div.mat-mdc-select-panel{
  padding: unset !important;
  .list-item{
    min-height: 2.5rem !important;
    padding: 6px !important;
    mat-pseudo-checkbox{
      display: none !important;
    }
  }
}

.ngx-search{
  .mat-select-search-input{
    min-height: 3rem !important;
    padding: 6px !important;
    line-height: unset !important;
  }
}

  
.mat-mdc-form-field{
  .mat-mdc-select-arrow-wrapper{
    svg{
      path{
        display: none;
      }
    }
  }
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description{
  flex-basis: unset !important;
}

.placeholder{
  background-color: transparent !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
  width: 100% !important;
}

.mdc-floating-label{
  text-align: unset !important;
}

.mat-mdc-option[aria-disabled=true].contains-mat-select-search{
  top: unset !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above{
  color: rgba(0, 0, 0, 0.6) !important;
}

.tooltip{
  --bs-tooltip-bg : white !important;
}