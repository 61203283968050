@import url('https://fonts.googleapis.com/css?family=Rubik');

#map {
	
	height: 100%;
	padding: 0 10px;
	z-index: 0;
	display: block;
	margin: 0 auto;
}

#progressbar  {
  background-color: black;
}

#progressbar > div {
  height: 14px;
  color: #3A4743;	
  font-family: 'Open Sans', sans-serif;	
  font-size: 20px;	
  font-weight: 600;	
  line-height: 26px;
}

.map-tooltip{
  background-color: red;
  text-align: right;
}
.tooltip-id {
  position: relative;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  &.border-bottom {
    border-bottom: 2px solid #ECECEC;
  }
  &.border-bottom-thin {
    border-bottom: 1px solid #ECECEC;
  }
  .tooltip-text{
    margin-top: 16px;
    font-weight: 400;
    display: inline-block;
    color: #3A4743;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
  }
  .tooltip-value {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #3A4743;
    &.bin-name {
      cursor: pointer;
    }
  }
}
.gm-style-iw + div {
  display: none;
}

.gm-style-iw {
	background-color: #FBFBFB;
	box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
	border-radius: 3px;
	overflow: visible !important;
  box-shadow: 2px 2px 13px 6px rgba(0,0,0,0.25);
  max-height: 300px !important;
  max-width: 100% !important;
}



.gm-style-iw > div{
  overflow: unset !important;
  max-height: unset !important;
}

.wazeIcon{
  margin: 0 5px -5px 5px !important;
}

#iw-container {
  position: relative;
  width: 100%;
}
#iw-container .iw-title {
	font-family: 'Open Sans', sans-serif;
	font-size: 22px;
	font-weight: 400;
	padding: 10px;
	background-color: #48b5e9;
	color: white;
  margin: 0;
  color: #3A4743;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  border-radius: 2px 2px 0 0;
}
.iw-subTitle {
	font-size: 16px;
	font-weight: 700;
}
.iw-bottom-gradient {
	position: absolute;
	width: 326px;
	height: 25px;
	bottom: 10px;
	right: 18px;
	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-underline {
	display: flex;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-inner-container.mat-checkbox-inner-container-no-side-margin {
  height: 13px;
  width: 13px;
}

td.mat-mdc-cell {
  font-size: 12px;
  border-bottom-width: 2px;
}

td.mat-mdc-cell.cdk-column-name.mat-column-name {
  cursor: pointer;
}

th.mat-mdc-header-cell {
  border-color: #BBBCBC;
  border-bottom-width: 2px;
  color: black;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #3A4743;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #3A4743;
}

.bin-list-view[_ngcontent-c15] .mat-mdc-card[_ngcontent-c15] .top-divider[_ngcontent-c15] {
  border-top-color: rgba(151, 151, 151, 0.14) !important;
  opacity: 1 !important;
  border-top-width: 2px;
}

td.mat-mdc-cell.cdk-column-status.mat-column-status.ng-star-inserted {
  padding-right: 0px;
}

.mat-mdc-paginator-container {
  justify-content: center !important;
}

.mat-mdc-card-header-text {
  width: 100%;
}

/*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card-header.mat-mdc-card-header {
  height: 40px;
  line-height: 40px;
}

.collection-report[_ngcontent-c13] .mat-mdc-card[_ngcontent-c13] .mat-mdc-card-content[_ngcontent-c13] {
    margin-top: 15px;
    position: relative;
}

.gm-style .gm-style-iw-c{
  padding: 0px !important;
  position: absolute;
  min-width: 270px !important;
  filter: drop-shadow(rgba(58, 71, 67, 0.3) 2px 6px 28px);
}

.mobile-address{
  text-decoration: underline;
  font-weight: 900;
}

.languageDirection{
  display: flex !important;
  margin-right: 3px;
}

.mobile-flex{
  display: flex !important;
}

.tooltip-address{
  position: relative;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #3A4743;
  margin-bottom: 3px;
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-divider-margin{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  gap: 8px;
  display: grid;
  margin-top: 12px;
}

.mobile-tooltip-address{
  position: relative;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #3A4743;
  flex: none;
  order: 1;
  flex-grow: 0;
  align-self: center;
  margin-bottom: 6px;
}

.mobile-tooltip-address-workplan{
  position: relative;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #3A4743;
  flex: none;
  order: 1;
  flex-grow: 0;
  align-self: center;
  margin-bottom: 6px;
}

.binNameHref-truckMap{
  color: unset;
  text-decoration: unset;
}

.binNameHref-truckMap:hover{
  text-decoration: unset;
}

.last{
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #5D7870 !important;
  margin-left: 16px;
  margin-right: 16px;
}

.tooltip-text-collection{
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #5D7870;
  margin-top: 16px;
  display: inline-block;
}

.spaceUp{
  margin-top: 0px !important;
}

.disp{
  display: flex;
}

.space-between{
  justify-content: space-between;
}

.mat-mdc-table {
  font-family: 'Open Sans', sans-serif;
}

.mat-mdc-tab-group {
  font-family: 'Open Sans', sans-serif;
}

.mat-mdc-card {
  font-family: 'Open Sans', sans-serif;
}

.rotation{
  transform: rotateY(180deg);
}

.prevNextArrow{
  height: 16px;
  width: 16px;
  text-align: center;
  padding: 0;
  background: 0 0;
  border: 0;
}

.tooltip-marker {
  padding: 4px 6px;
  background: #2A3338;
  border-radius: 6px;
  position: relative;
  font-family: 'Open Sans', sans-serif;
  width: fit-content;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: #FFFFFF;
  bottom: 9px;
}
.tooltip-arrow-marker {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 99%;
  overflow: hidden;
  background: transparent;
}
.tooltip-arrow-marker::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background: #2A3338;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  left: 50%;
  box-shadow: 0 0 16px #0004;
  border: 1px solid #aaa;
}

.title-info{
  color: var(--new-black-50, #68747A);
  font-weight: 400;
  text-wrap: nowrap;
}

.value-info{
  color: var(--new-black-90, #2A3338);
  font-weight: 600;
  text-wrap: nowrap;
}

.header-tooltip{
  color: var(--new-black-90, #2A3338);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  gap: 3px;
}

.gap{
  gap: 3px;
}

.address-waze{
  display: flex;
  align-items: center;
}

.tooltip-id-devider-margin{
  margin-bottom: 9px;
}

.tooltip-action-btn{
  font-family: 'Open Sans', sans-serif;
  border-radius: 30px;
  border: 1px solid var(--new-black-10, #CDD2D4);
  background: var(--new-white, #FFF);
  padding: 0px 12px;
  color: var(--new-shiny-green-400, #28B365);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}

.mobile-tooltip-action-btn{
  font-family: "Open Sans", sans-serif;
  border-radius: 30px;
  border: 1px solid var(--new-black-10, #CDD2D4);
  background: var(--new-white, #FFF);
  padding: 4px 16px;
  color: var(--new-shiny-green-400, #28B365);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

google-map{
	.map-container{
		width: 100% !important;
		height: 100% !important;
	}
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #607d8b !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #607d8b !important;
}

.binInfo_btn{
  background: transparent;
  border: none;
  padding: unset;
  outline: unset;
}

.btnIntallationWizardCarousel{
  color: rgba(13, 110, 253);
  background: transparent;
  border: none;
  padding: unset;
}

.gm-style .gm-style-iw-d{
  padding: 0px 16px 16px 16px;
}

.gm-style-iw-chr > button{
  width: auto !important;
  height: auto !important;
}

.gm-style-iw-chr > button > span{
  margin: 4px 8px 0px 8px !important;
  width: 14px !important;
  height: 14px !important;
}

.mdc-list-item:focus::before {
  background-color: transparent !important; 
}