
.workplan-drop-down {
  width: 420px;
  line-height: 0.1 !important;
}

.mat-pseudo-checkbox {
  height: 14px !important;
  width: 14px !important;
  border-radius: 2px !important;
}
.mdc-list-item__primary-text{	
  color: #3A4743 !important;	
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12px !important;	
  line-height: 14px !important;
}
.choose-all-title{
  font-weight: bold;
}
.no-checkbox{
  .mat-pseudo-checkbox{ display: none !important; }
}
.no-border {
  &.mat-mdc-option{
    border-bottom: 0!important;
  }
}

.mat-pseudo-checkbox-checked{
	background-color: #3A4743!important;
}
.mat-pseudo-checkbox-checked::after {
  top: 2px !important;
  left: 2px !important;
  width: 11px !important;
  height: 4px !important;
}

.chosen-truck , .chosen-collection{
  background-color: #9B9B9B !important;
  .mdc-list-item__primary-text{
    color: #FFFFFF !important;
    font-weight: bold;
  }
}
.mat-mdc-option{
  border-bottom: 1px solid #80808073;
.inventory-table{
  tr.mat-mdc-header-row{
    height: 29px !important;
    background-color: #E0E2DF;

  } 
  th.mat-mdc-header-cell{
    color: #3A4743;	
    font-family: 'Open Sans', sans-serif;	
    font-size: 12px;	
    font-weight: bold;
  }
}

}
.mat-mdc-option.rtl {  
    margin-left: 0;  
}
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-wrapper{
  height: 14px !important;

}
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-horizontal{
  width: 100% !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-track-fill{
  height: 14px !important;
  background-color: #3A4743 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-track-background {
  height: 14px !important;
  background-color: #f6f6f6 !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-track-wrapper {
  height: 14px !important;
  border-radius: 10px;
}
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-thumb{
  position: absolute;
  top: -10px;
  border: 1px solid #3A4743 !important;	
  border-radius: 10px;	
  background-color: #FFFFFF !important;
}
.c-1-color { background: #28B365; }
.c-2-color { background: #FFD654; }
.c-3-color { background: #FF4E3E; }
.max-color { background: #9DA9AD; }
.noUi-tooltip {
  border:none;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  line-height: 12px;  
}
.manage-bin-modal{
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-label{
    display: flex !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-inner-container{
    margin: unset;
    margin-right: 8px;
    height: 14px;
    width: 14px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-layout{
    align-items: end;
  }
}


input:-webkit-autofill {
  background-color: red !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.mat-mdc-card-header{
  padding: 0px 16px !important;
}

.mdc-data-table__cell{
  text-align: unset !important;
}

td.mat-mdc-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label{
  color: var(--new-black-90, #2A3338) !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.mat-mdc-tab .mdc-tab__text-label{
  color: var(--new-primary-black-50, #2A3338) !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline{
  border-color: #68747A !important;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar{
  --mat-tab-header-active-ripple-color: #68747A;
  --mat-tab-header-inactive-ripple-color: #68747A;
}

.mat-mdc-row, .mdc-data-table__content{
  line-height: unset !important;
}

.mat-mdc-header-row{
  line-height: unset !important;
}

.mdc-checkbox__background{
  border-width: 2px !important;
  border-style: solid !important;
  border-color: #e1e1ea !important;
  width: 14px !important;
  height: 14px !important;
}

.mat-mdc-card-content{
  padding: 0 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background{
  background-color: #3A4743 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark{
  color: white !important;
  border-color: white !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark{
  color: white !important;
  border-color: white !important;
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background{
  background-color: #3A4743 !important;
}

.mdc-checkbox__checkmark-path{
  stroke-width: 5.2px !important;
  stroke-dasharray: 30 !important;
}

.mat-mdc-checkbox-ripple,
.mat-mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}

.mat-mdc-checkbox-checked{
  .mdc-checkbox__background{
    border-color: #3A4743 !important;
  }
}

.mdc-checkbox__background{
  border-color: rgba(0, 0, 0, 0.54)  !important;
}

.mdc-checkbox{
  width: 14px !important;
  height: 14px !important;
  flex: unset !important;
}

.mdc-dialog__surface{
  box-shadow: none !important;
  overflow-y: unset !important;
}

.mat-content.mat-content-hide-toggle{
  margin-right: unset !important;
}

.mat-mdc-form-field-infix{
  min-height: unset !important;
}

.mat-mdc-form-field-subscript-wrapper{
  display: none !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled){
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-line-ripple{
  display: none !important;
}

.mat-mdc-select{
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important; 
}

.mat-mdc-autocomplete-panel{
  mat-pseudo-checkbox{
    display: none !important;
  }
}

.mdc-list-item__primary-text{
  margin-right: unset !important;
  width: 100%;
}

.mdc-tab{
  letter-spacing: unset !important;
}

.ngb-dp-weekday {
  color: #17a2b8 !important;
  font-size: 80% !important;
}

.mat-mdc-radio-button.mat-accent{
  --mat-radio-checked-ripple-color:transparent;
}

.mdc-data-table__header-cell{
  text-align: unset !important;
}

ngb-datepicker-navigation-select>.form-select{
  --bs-form-select-bg-img: unset !important;
}

.mat-mdc-tab:not(.mdc-tab--stacked){
  height: auto !important;
  padding: 8px 6px;
  min-width: auto;
}

.mat-mdc-tab-labels{
  gap: 24px !important;
  border-bottom: 1px solid #CDD2D4;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline{
  border-top-width: 1px !important;
}
